<template>
  <div class="alerts-search-comp">
    <b-card class="notification-search position-relative main-card-padding">
      <div class="m-2-br-notification-bottom">
        <div class="adduser-btn-row">
          <h4 class="card-title">
            {{ $t("Notification.Notifications") }}
          </h4>
          <div class="filter-btn-outer-new d-flex">
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('Notification.AddNotification')"
              variant="primary"
              class="btn-icon desktop-hidden mr-1"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.NOTIFICATION
                })
              "
              @click="addNotification"
            >
              <feather-icon icon="PlusIcon" size="12" />
            </b-button>
            <b-button
              variant="primary"
              class="desktop-hidden"
              size="sm"
              @click="showFiltermenu = !showFiltermenu"
            >
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>
          </div>
          <div
            class="d-flex align-items-center flex-wrap filter-card"
            v-if="showFiltermenu"
          >
            <div class="alert-per-page mr-1">
              <div class="addXIcon">
                <feather-icon
                  class="sizeIcon"
                  @click="showFiltermenu = !showFiltermenu"
                  icon="XIcon"
                  size="15"
                />
              </div>
              <label class="search-label">{{
                $t("Notification.Entries")
              }}</label>
              <v-select
                :clearable="false"
                id="vue-select"
                class="per-page-selector d-inline-block fix-83"
                v-model="perPage"
                :options="option"
              />
            </div>
            <search
              style="float: left"
              class="search-comp no-btm-0"
              v-model="searchFiled"
              :searchColumn="searchColumn"
              :operator="operator"
            ></search>
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('Notification.AddNotification')"
              variant="primary"
              class="mobile-hidden"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.NOTIFICATION
                })
              "
              @click="addNotification"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="mobile-hidden">{{
                $t("Notification.AddNotification")
              }}</span>
            </b-button>
          </div>
        </div>
      </div>

      <NotificationList
        :searchFiled="searchFiled"
        :perPage="perPage"
      ></NotificationList>
    </b-card>
  </div>
</template>

<script>
import { BCard, BAlert, BButton, VBTooltip } from "bootstrap-vue";
import NotificationList from "@/views/Notification/NotificationList.vue";
import constants from "@/utils/constants";
import Search from "../Common/search.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    VBTooltip,
    NotificationList,
    Search,
    vSelect
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      perPage: this.$route.query.perPage ? this.$route.query.perPage : this.nearestPage(["10", "20", "30"],parseInt(window.innerHeight / 71)),
      option: ["10", "20", "30"],
      showFiltermenu: false
    };
  },
  props: [],
  watch: {},
  mounted() {
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    addNotification() {
      this.$router.push({ name: "notification-create" });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.notification-search {
  .notification-per-page {
    margin-left: 0;
    margin-right: 15px;
  }
  .search-filter {
    margin-left: 0;
  }
}
</style>
