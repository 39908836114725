import APIService from "./api";

/** This class will include all services defined in notification-service */
export default class DeviceService extends APIService {
  service = "notification-service";
  async getNotifications(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getNotifications" },
      data,
      options
    );
  }
  async getNotification(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getNotification" },
      data,
      options
    );
  }
  async getAlertNotificationChannels(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAlertNotificationChannels" },
      data,
      options
    );
  }
  async getTeams(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTeams" },
      data,
      options
    );
  }
  async getUsers(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUsers" },
      data,
      options
    );
  }
  async createNotification(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createNotification" },
      data,
      options
    );
  }
  async updateNotification(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateNotification" },
      data,
      options
    );
  }
  async removeNotification(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "removeNotification" },
      data,
      options
    );
  }
  async getNotificationActivity(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getNotificationActivity" },
      data,
      options
    );
  }
  async getNotificationActivityDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getNotificationActivityDetails" },
      data,
      options
    );
  }
  async getNotificationSelectedUnitsOrGroups(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getNotificationSelectedUnitsOrGroups" },
      data,
      options
    );
  }
}
