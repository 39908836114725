<template>
  <div class="notification-list">
    <b-card no-body class="mb-0">
      <div class="table-responsive mb-0" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        class="position-relative no-headers userlist-table child-1-30-percent notification-scroll"
        responsive
        show-empty
        v-if="!show"
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(user)="row">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">{{ row.item.name }}</h6>
            <small class="text-muted">{{
              row.item.notification && row.item.notification.name
            }}</small>
          </b-media>
        </template>
        <template #cell(channels)="row">
          <Badge
            :tags="row.item.channels"
            :showTextInBadge="Number(7)"
            :event="row.item"
            :maxTagShow="Number(3)"
          ></Badge>
        </template>
        <template #cell(status)="row">
          <b-badge
            pill
            :variant="
              row.item.alert.removed
                ? 'light-danger'
                : row.item.status
                ? 'light-success'
                : 'light-danger'
            "
          >
            {{
              row.item.status
                ? $t("Notification.Active")
                : $t("Notification.InActive")
            }}
          </b-badge>
        </template>
        <template #cell(action)="row">
          <div class="text-right list-icon">
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.NOTIFICATION
                })
              "
              v-b-tooltip.hover.top="$t('tooTip.view')"
            >
              <b-link
                :to="{ name: 'notification-view', params: { id: row.item.id } }"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="text-primary action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                />
              </b-link>
            </span>

            <span
              v-b-tooltip.hover.top="$t('tooTip.update')"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.NOTIFICATION
                }) &&
                row.item.allow_edit &&
                !row.item.alert.removed
              "
            >
              <b-link
                :to="{ name: 'notification-edit', params: { id: row.item.id } }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="text-primary action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                ></feather-icon>
              </b-link>
            </span>
            <span
              v-b-tooltip.hover.top="$t('tooTip.delete')"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.DELETE,
                  subject: constants.PERMISSIONS_MODEL.NOTIFICATION
                }) && !row.item.auto_created
              "
            >
              <feather-icon
                icon="Trash2Icon"
                @click="close(row.item)"
                class="text-danger action-icon"
                size="18"
                style="position: relative; cursor: pointer"
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalNotification > 0"
              v-model="currentPage"
              :total-rows="totalNotification"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      :data="removedNotificationData"
      :onClose="onClose"
      :removedUser="removedNotification"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";

import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UnitService from "@/libs/api/unit-service";
import NotificationService from "@/libs/api/notification-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Badge from "@/layouts/components/Badge.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    AreYouSureModal,
    VBTooltip,
    BSkeletonTable,
    Badge
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        {
          key: "user",
          Class: "",
          label: this.$t("Notification.tableColumns.user")
        },
        {
          key: "channels",
          tdClass: "group-width",
          label: this.$t("Notification.tableColumns.channels")
        },
        {
          key: "status",
          tdClass: "status-width",
          label: this.$t("Notification.tableColumns.status")
        },
        {
          key: "action",
          tdClass: "notification-action-width",
          label: this.$t("Notification.tableColumns.action")
        }
      ],
      totalNotification: 0,
      timeoutId: null,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      activeClass: "active",
      items: [],
      removedNotificationData: {},
      show: false
    };
  },
  props: ["searchFiled", "perPage"],
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
    this.debounceGetAllNotifications();
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllNotifications();
    },
    currentPage(newPage) {
      this.debounceGetAllNotifications();
    },

    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounceGetAllNotifications();
    }
  },
  methods: {
    debounceGetAllNotifications() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllNotifications();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    async getAllNotifications() {
      try {
        this.show = true;
        const me = this;
        if (!this.filter || !this.filter.value) {
          this.filter = {};
        }
        let requestData = {
          page: parseInt(this.currentPage),
          page_size: parseInt(this.perPage),
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };
        const alertFilter =
          this.$route.query && this.$route.query.alert_id
            ? { alert_id: this.$route.query.alert_id }
            : {};
        let response = await new NotificationService().getNotifications({
          ...requestData,
          ...alertFilter
        });

        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.del_tool = `del${u.id}`;
            u.up_tool = `up${u.id}`;
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalNotification =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
      this.debounceGetAllNotifications();
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllNotifications();
    },
    close(data) {
      this.removedNotificationData = data;
      this.$bvModal.show("modal-sm-remove");
    },
    async removedNotification(params) {
      try {
        this.show = true;
        if (!params || !params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
        let response = await new NotificationService().removeNotification({
          id: params.id
        });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("Notification.RemoveSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.onClose();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
.notification-action-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.group-width {
  text-align: right;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}
.notification-search {
  .search-filter {
    margin-right: 15px;
  }
}

.notification-list {
  .table-responsive {
    max-height: calc(100vh - 270px) !important;
    min-height: calc(100vh - 270px) !important;
    overflow-y: auto;
  }

  .notification-scroll {
    min-height: calc(100vh - 270px) !important;
    min-height: calc(100vh - 270px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
}
</style>
