var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification-list"},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.show)?_c('div',{staticClass:"table-responsive mb-0"},[(_vm.show)?_c('b-skeleton-table',{attrs:{"rows":10,"columns":1,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative no-headers userlist-table child-1-30-percent notification-scroll",attrs:{"responsive":"","show-empty":"","align-v":"end","items":_vm.items,"fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound')},scopedSlots:_vm._u([{key:"cell(user)",fn:function(row){return [_c('b-media',{staticClass:"align-item-center",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":"RA","text":row.item.avtar_txt,"variant":row.item.variant}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(row.item.name))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(row.item.notification && row.item.notification.name))])])]}},{key:"cell(channels)",fn:function(row){return [_c('Badge',{attrs:{"tags":row.item.channels,"showTextInBadge":Number(7),"event":row.item,"maxTagShow":Number(3)}})]}},{key:"cell(status)",fn:function(row){return [_c('b-badge',{attrs:{"pill":"","variant":row.item.alert.removed
              ? 'light-danger'
              : row.item.status
              ? 'light-success'
              : 'light-danger'}},[_vm._v(" "+_vm._s(row.item.status ? _vm.$t("Notification.Active") : _vm.$t("Notification.InActive"))+" ")])]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"text-right list-icon"},[(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.READ,
                subject: _vm.constants.PERMISSIONS_MODEL.NOTIFICATION
              })
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.view')),expression:"$t('tooTip.view')",modifiers:{"hover":true,"top":true}}]},[_c('b-link',{attrs:{"to":{ name: 'notification-view', params: { id: row.item.id } }}},[_c('feather-icon',{staticClass:"text-primary action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"EyeIcon","size":"18"}})],1)],1):_vm._e(),(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                subject: _vm.constants.PERMISSIONS_MODEL.NOTIFICATION
              }) &&
              row.item.allow_edit &&
              !row.item.alert.removed
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.update')),expression:"$t('tooTip.update')",modifiers:{"hover":true,"top":true}}]},[_c('b-link',{attrs:{"to":{ name: 'notification-edit', params: { id: row.item.id } }}},[_c('feather-icon',{staticClass:"text-primary action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"EditIcon","size":"18"}})],1)],1):_vm._e(),(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                subject: _vm.constants.PERMISSIONS_MODEL.NOTIFICATION
              }) && !row.item.auto_created
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.delete')),expression:"$t('tooTip.delete')",modifiers:{"hover":true,"top":true}}]},[_c('feather-icon',{staticClass:"text-danger action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.close(row.item)}}})],1):_vm._e()])]}}],null,false,557718944)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalNotification > 0)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalNotification,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","no-paging-nav":false,"active-class":_vm.activeClass},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1),_c('AreYouSureModal',{attrs:{"data":_vm.removedNotificationData,"onClose":_vm.onClose,"removedUser":_vm.removedNotification}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }